import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Dialog,
  Box,
  Typography,
  DialogContent,
  Grid,
  IconButton,
  DialogActions,
  Button,
  CircularProgress
} from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";

// Contexts
import TokenContext from "../../context/TokenContext";
import { AlertContext } from "../../context/AlertContext";
import { OperationalModeContext } from "../../context/OperationalModeContext";
import PreferenceContext from "../../context/PreferenceContext";
import ThreadContext from "../../context/ThreadContext";

// Themes
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import highContrastTheme from "../../themes/highContrastTheme";

// API Calls
import getDocumentList from "../../api/getDocumentList";
import getDocumentMetadata from "../../api/getDocumentMetadata";
import deleteDocumentMetadata from "../../api/deleteDocumentMetadata";
import deleteDocument from "../../api/deleteDocument";
import getDocument from "../../api/getDocument";

// Components
import DocumentInfo from "../DocumentInfo/DocumentInfo";
import UploadResources from "../UploadResources/UploadResources";
import DocumentTable from "../DocumentTable/DocumentTable";
import BaseTooltip from "../../base_components/BaseTooltip";

const DocumentList = ({ open, handleOpen, handleClose }) => {
  const [documents, setDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteDocIDs, setDeleteDocIDs] = useState([]);
  const [infoOpen, setInfoOpen] = useState(false);
  const preferenceCxt = useContext(PreferenceContext);
  const tokenCxt = useContext(TokenContext);
  const { showAlert } = useContext(AlertContext);
  const { selectedUserDocuments, removeSelectedUserDocument, saveDocuments } = useContext(OperationalModeContext);
  const [stagingThreadPreferences, setStagingThreadPreferences] = useState({});
  const threadCxt = useContext(ThreadContext);


  useEffect(() => {
    !threadCxt.threadID && !!preferenceCxt.preferenceList && setStagingThreadPreferences({...preferenceCxt.preferenceList});
    // !threadCxt.threadID && !!preferenceCxt.preferenceList && setStagingThreadPreferences({...preferenceCxt.preferenceList});
  }, [threadCxt.threadID, preferenceCxt.preferenceList])

  const preferredTheme = preferenceCxt.preferenceList.theme;

  const popUpButtonColor = {
    1: lightTheme.palette.text.primary,
    2: darkTheme.palette.text.primary,
    3: highContrastTheme.palette.text.primary,
  };

  const popUpButtonBackground = {
    1: lightTheme.palette.background.default,
    2: darkTheme.palette.background.default,
    3: highContrastTheme.palette.background.default,
  };

  const popUpButtonStyle = {
    color: popUpButtonColor[preferredTheme],
    background: popUpButtonBackground[preferredTheme],
  };

  useEffect(() => {
    if (open) {
      fetchAndProcessDocuments();
    }
  }, [open]);

  const fetchAndProcessDocuments = useCallback(async () => {
    try {
      setIsLoading(true); 
      const res = await getDocumentList(tokenCxt.token);
      const docDataFromDB = await getDocumentMetadata(tokenCxt.token);

      if (res.status !== 200 || !res.data.documents || res.data.documents.length === 0) {
        setDocuments([]);
        setIsLoading(false);
        return;
      }

      const processedDocuments = processDocuments(res.data.documents, docDataFromDB.data);
      setDocuments(processedDocuments);
      setIsLoading(false); 
    } catch (error) {
      console.error("Error fetching documents:", error);
      setIsLoading(false); 
      setDocuments([]);
      showAlert("Error fetching documents. Please try again later.", "error");
    }
  }, [tokenCxt.token]);

  const processDocuments = useCallback((documents, documentData) => {
    const updatedDocumentData = documentData.map(docData => {
      const full_identifier = docData.full_identifier;
      return {
        ...docData,
        full_identifier: full_identifier,
      };
    });

    const mimeToDisplay = {
      "application/json": "JSON",
      "application/pdf": "PDF",
      "text/plain": "TEXT",
      "text/html": "HTML",
      "text/xml": "XML",
      "text/csv": "CSV",
      "Doc": "DOC",
    };

    return updatedDocumentData
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map((doc) => ({
        id: doc.full_identifier,
        document_id: doc.document_id,
        docName: doc.document_name,
        docType: mimeToDisplay[doc.document_type],
        tags: doc.tags,
        size: doc.size,
        createdAt: new Date(doc.created_at).toLocaleString(),
        embeddingModel: doc.embedding_model || "Text Embedding ADA v2",
        chunkerType: doc.chunker_type || "Text Chunker",
      }));
  }, []);

  const handleCloseDeleteConfirm = () => {
    setDeleteDocIDs([]);
    setConfirmDelete(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    const numberOfDocsToDelete = deleteDocIDs.length;
    const docIDMap = documents.reduce((acc, cur) => {
      acc[cur.id] = cur.document_id;
      return acc;
    }, {});
    try {
      await Promise.all(deleteDocIDs.map(deleteDocID => {
        const docName = deleteDocID.split("/")[1];

        return deleteDocumentMetadata(tokenCxt.token, docIDMap[deleteDocID])
          .then((res) => {
            if (res.status === 200) {
              return deleteDocument(tokenCxt.token, docName)
                .then((res) => {
                  if (res.status === 200) {
                    setIsDeleting(false);
                    if (selectedUserDocuments.some((doc) => doc.id === deleteDocID)) {
                      removeSelectedUserDocument({ id: deleteDocID });
                    }
                  } else {
                    setIsDeleting(false);
                    showAlert("Error deleting resource from Azure", "error", 2000, "bottom");
                  }
                });
            } else {
              setIsDeleting(false);
              showAlert("Error deleting resource from database", "error", 2000, "bottom");
            }
          });
      }));

      const newDocuments = documents.filter((document) => !deleteDocIDs.includes(document.id));
      setDocuments(newDocuments);
      showAlert(`${numberOfDocsToDelete} Resource(s) deleted`, "success", 2000, "bottom");
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error('Error deleting resources:', error);
      showAlert("Error deleting resources", "error", 2000, "bottom");
    }
  };

  const handleOpenDeleteConfirm = (ids) => {
    setDeleteDocIDs(ids);
    setConfirmDelete(true);
  };

  const handleInfoClick = async (docId) => {
    try {
      const docName = docId.split("/")[1];
      const res = await getDocument(tokenCxt.token, docName);

      if (res.status === 200) {
        setSelectedDoc(res.data);
      } else {
        showAlert("Error fetching resource. Please try again later.", "error");
      }
    } catch (error) {
      showAlert("Error fetching resource. Please try again later.", "error");
    }
  };

  useEffect(() => {
    if (selectedDoc) {
      setInfoOpen(true);
    }
  }, [selectedDoc]);

  const handleSelectAll = async () => {
    const newSelectedDocs = documents.map((doc) => doc.id);
    await saveDocuments("User Uploaded Doc Q&A", newSelectedDocs);
    showAlert("All resources activated", "success", 2000, "bottom");
  };

  const handleDeselectAll = async () => {
    await saveDocuments("General", []);
    showAlert("All resources deactivated", "success", 2000, "bottom");
  };

  const handleUploadClick = () => {
    setDrawerOpen(true);
  };

  const handleActivateClick = async (doc_ids) => {
    let updatedSelectedDocuments = [...selectedUserDocuments.map(doc => doc.id)];

    doc_ids.forEach(id => {
      const isActivated = updatedSelectedDocuments.some((doc_id) => doc_id === id);
      updatedSelectedDocuments = isActivated
        ? updatedSelectedDocuments.filter((doc_id) => doc_id !== id)
        : [...updatedSelectedDocuments, id];
    });

    if (updatedSelectedDocuments.length === 0) {
      await saveDocuments("General", []);
      return;
    }

    const response = await saveDocuments("User Uploaded Doc Q&A", updatedSelectedDocuments);
    if (response)
      showAlert(`Selected resources activated`, "success", 2000);
    else
      showAlert(`Error activating selected resources`, "error", 2000);
  };

  return (
    <>
      { drawerOpen && <UploadResources open={() => setDrawerOpen(true)} close={() => setDrawerOpen(false) } stagingThreadPreferences={stagingThreadPreferences} />}
      <Dialog open={open} fullWidth maxWidth="xl" onClose={handleClose}>
        <Grid sx={{ p: 4 }}>
          <Typography variant="h5" fontWeight="bold" sx={{ flexGrow: 1, textAlign: 'center' }}>
            My Resources
          </Typography>
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <DialogContent>
          <Box sx={{ position: 'relative' }}>
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1 }}>
                <CircularProgress color="primary" />
              </Box>
            )}
            <>
              {documents.length === 0 ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="30vh">
                  <BaseTooltip isArrow title="Upload Resource">
                    <UploadIcon onClick={handleUploadClick} fontSize="large" sx={{ cursor: 'pointer' }} />
                  </BaseTooltip>
                  <Typography variant="h6" align="center" style={{ marginTop: 16 }}>
                    You have no resources uploaded yet. Click the upload icon to upload a resource.
                  </Typography>
                </Box>
              ) : (
                <Box height="70vh" position="relative">
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box display="flex" justifyContent="center">
                        <DocumentTable
                          data={documents}
                          handleInfoClick={handleInfoClick}
                          onUploadClick={handleUploadClick}
                          onDeleteClick={handleOpenDeleteConfirm}
                          selectedUserDocuments={selectedUserDocuments}
                          onDeselectAllClick={handleDeselectAll}
                          onSelectAllClick={handleSelectAll}
                          handleActivateClick={handleActivateClick}
                          stagingThreadPreferences={stagingThreadPreferences}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          </Box>
        </DialogContent>

        <Dialog open={confirmDelete} onClose={handleCloseDeleteConfirm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogContent>
          {isDeleting && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1 }}>
                <CircularProgress color="primary" />
              </Box>
            )}
            <>
            {deleteDocIDs.length === 1 ? (
              <Typography>
                Are you sure you want to delete this document?
              </Typography>
            ) : (
              <Typography>
                Are you sure you want to delete these {deleteDocIDs.length} documents?
              </Typography>
            )}
            </>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #444" }}>
            <Button onClick={handleCloseDeleteConfirm} sx={popUpButtonStyle}>
              Cancel
            </Button>
            <Button onClick={handleDelete} autoFocus sx={popUpButtonStyle}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <DocumentInfo key={selectedDoc ? selectedDoc.id : "no-document"} open={infoOpen} onClose={() => setInfoOpen(false)} document={selectedDoc || null} />
      </Dialog>
    </>
  );
};

export default DocumentList;
