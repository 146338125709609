import React, { useState, useContext } from "react";
import {
    Button,
    Box,
    Typography,
    Grid,
    Paper,
    CircularProgress,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import UploadIcon from "@mui/icons-material/CloudUpload";

// import BaseButton from "../../base_components/BaseButton";

import postDocument from "../../api/postDocument";
import postDocumentMetadata from "../../api/postDocumentMetadata";
import getDocumentMetadata from "../../api/getDocumentMetadata";

import TokenContext from "../../context/TokenContext";
import { AlertContext } from "../../context/AlertContext";
import PreferenceContext from "../../context/PreferenceContext";
import ThreadPreferenceContext from "../../context/ThreadPreferenceContext";
import BaseTextField from "../../base_components/BaseTextField";

// themes
import lightTheme from "../../themes/lightTheme";
import darkTheme from "../../themes/darkTheme";
import highContrastTheme from "../../themes/highContrastTheme";

import { pdfjs } from 'react-pdf';
import DropZone from "../DropZone/DropZone";
import { useTheme } from "@emotion/react";
import BasePaper from "../../base_components/BasePaper";
import { dark } from "@mui/material/styles/createPalette";

import { CHUNKER_TYPE_NUMERIC_TO_READABLE_WORDS, EMBEDDING_MODELS_NUMERIC_TO_READABLE_WORDS } from "../../config/constants";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

// @ts-expect-error This does not exist outside of polyfill which this is doing
if (typeof Promise.withResolvers === 'undefined') {
    if (window)
        // @ts-expect-error This does not exist outside of polyfill which this is doing
        window.Promise.withResolvers = function () {
            let resolve, reject;
            const promise = new Promise((res, rej) => {
                resolve = res;
                reject = rej;
            });
            return { promise, resolve, reject };
        };
}
// there is your `/legacy/build/pdf.worker.min.mjs` url
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
//     import.meta.url
// ).toString();



const UploadFile = ({currentChunkerType, currentEmbeddingModel}) => {
    const tokenCxt = useContext(TokenContext);
    const { showAlert } = useContext(AlertContext);
    const acceptedFiles = [".html", ".txt", ".csv", ".json", ".pdf", ".xml", ".py", ".js", ".jsx", ".java", ".cpp", ".cs", ".go", ".rs", ".scala", ".rb", ".tex", ".md"];
    const [isLoading, setIsLoading] = useState(false);
    const [openCustomDocDialog, setOpenCustomDocDialog] = useState(false);
    const [docPreview, setDocPreview] = useState(false);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [files, setFiles] = useState(null);

    const preferenceCxt = useContext(PreferenceContext);
    const preferredTheme = preferenceCxt.preferenceList.theme;

    const chunkerType = currentChunkerType;
    const embeddingModel = currentEmbeddingModel;

    const [documentInfo, setDocumentInfo] = useState({
        title: "",
        rawText: "",
        url: "",
        org: "Risk",
        vertical: "",
        department: "",
        product: "",
        team: "",
        customMetadata: [],
        accessibility: "",
        accessibleBy: [],
        isCustom: false
    });

    const initialDocumentState = {
        title: "",
        rawText: "",
        size: 0,
        url: "",
        org: "Risk",
        vertical: "",
        department: "",
        product: "",
        team: "",
        customMetadata: [],
        accessibility: "",
        accessibleBy: [],
        isCustom: false
    };

    const theme = useTheme();

    const popUpButtonStyle = {
        color: theme.palette.text.primary,
        background: theme.palette.background.default
    }

    const backgroundColor = {
        1: lightTheme.palette.background.default,
        2: darkTheme.palette.background.tabPaper,
        3: highContrastTheme.palette.background.default,
    };

    const TabPaperStyle = {
        p: 2,
        mb: 2,
        backgroundColor: backgroundColor[preferredTheme],
        width: "100%",
    };

    const handleFile = (acceptedFiles) => {
        setFiles(acceptedFiles);
    }
    
    const handleOpenCustomDocDialog = (preview = false, foundFile = initialDocumentState) => {
        if (!!preview) {
            setDocPreview(true);
            setDocumentInfo(foundFile);
        }
        setOpenCustomDocDialog(true);
    }

    const handleCloseCustomDocDialog = () => {
        setOpenCustomDocDialog(false);
        setDocumentInfo(initialDocumentState);
        setDocPreview(false);
    }

    const calculateFileSize = (rawText) => {
        let sizeInBytes = new Blob([rawText]).size;
        console.log('sizeInBytes', sizeInBytes);
        return sizeInBytes;
    };

    const handleAddCustomDoc = () => {
        if (documentInfo.title === "" || documentInfo.rawText === "") {
            showAlert("Title and content for the document cannot be empty", "error");
            return;
        }
        const foundFile = uploadFiles.filter(file => file.title === documentInfo.title);
        if (foundFile.length > 0) {
            showAlert("Document with the same title already exists! Please remove the previous file first", "error");
            return;
        }
        setUploadFiles(previousUploadFiles => [
            ...previousUploadFiles,
            Object.assign({ ...initialDocumentState }, {
                title: documentInfo.title,
                tags: documentInfo.tags,
                rawText: documentInfo.rawText,
                size: documentInfo.size || calculateFileSize(documentInfo.rawText),
                url: documentInfo.url,
                org: documentInfo.org,
                vertical: documentInfo.vertical,
                department: documentInfo.department,
                product: documentInfo.product,
                team: documentInfo.team,
                customMetadata: documentInfo.customMetadata,
                accessibility: documentInfo.accessibility,
                accessibleBy: documentInfo.accessibleBy,
                isCustom: true,
                type: "text/plain",
                name: documentInfo.title
            })

        ])
        handleCloseCustomDocDialog();

    }

    const handleOpenPreview = filename => {
        const foundFile = uploadFiles.filter(file => file.name === filename)[0];
        handleOpenCustomDocDialog(true, foundFile);
    }

    const handleOnUploadProgress = (event) => {
        const percentage = Math.floor((event.loaded * 100) / event.total);
    };

    const removeAllUploadFiles = () => {
        setUploadFiles([]);
    }

    const removeUploadFile = (filename) => {
        setUploadFiles(previousUploadFiles => previousUploadFiles.filter(file => file.name !== filename));
    }

    const generateUniqueName = (filename, existingFiles) => {
        // Return the filename as is if it's unique already
        if (!existingFiles.find(file => file.document_name.toLowerCase() === filename.toLowerCase())) {
            return filename;
        }

        let count = 1;
        let name = filename;
        const fileExtension = filename.slice((Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1);
        const lastDotIndex = filename.lastIndexOf(".");
        const baseName = lastDotIndex === -1 ? filename : filename.slice(0, lastDotIndex);

        while (existingFiles.find(file => file.document_name.toLowerCase() === name.toLowerCase())) {
            name = fileExtension
                ? `${baseName}.${fileExtension} (${count++})`
                : `${baseName} (${count++})`;
        }
        return name;
    }

    const handleSubmit = async () => {
        const formData = new FormData();
        const docDataFromDB = await getDocumentMetadata(tokenCxt.token);
        const existingFiles = docDataFromDB.data
        uploadFiles.forEach((file, index) => {
            console.log("File", file);
            let uniqueName = generateUniqueName(file.title, existingFiles);
            const metadata = {};
            file.customMetadata.forEach((entry) => {
                metadata[entry.key] = entry.value;
            });

            const newFile = {
                title: uniqueName,
                raw_text: file.rawText,
                size: file.size || calculateFileSize(file.rawText),
                url: file.url,
                organization: file.org,
                vertical: file.vertical,
                department: file.department,
                product: file.product,
                team: file.team,
                custom_metadata: JSON.stringify(metadata),
                accessibility: file.accessibility,
                accessible_by: JSON.stringify(file.accessibleBy),
                owner: tokenCxt.userName
            }

            if ("file" in file) {
                formData.append(`file_${index}`, file.file);
            }
            formData.append("files[]", JSON.stringify(newFile));
        })

        setIsLoading(true);
        try {
            const response = await postDocument(formData, tokenCxt.token, tokenCxt.userName, handleOnUploadProgress);
            if (response.status === 200) {
                showAlert("Document successfully uploaded!", "success");

                // Call postDocumentData once the document is uploaded
                try {
                    const uploadedBlobUrls = response.data;
                    console.log("uploadedBlobUrls", uploadedBlobUrls);
                    // For each string in uploadedBlobUrls, split by "/" and get the 5th and 6th element
                    const documentIds = uploadedBlobUrls.map(url => {
                        let urlParts = url.split("/");
                        urlParts = urlParts[4] + "/" + urlParts[5];
                        //Split urlParts by ? and take the first element
                        return decodeURIComponent(urlParts.split("?")[0]);
                    });
                    if (files) {
                        const fileObjects = files.map(({ name, size, type, lastModified }) => ({ name, size, type, lastModified }));

                        const payload = uploadFiles.map((doc) => {
                            //Find the id in documentIds that corresponds to the current document by checking for the id in documentIds that contains the document name doc.name
                            const documentId = documentIds.find(id => id.includes(doc.name));

                            return {
                                "document_name": generateUniqueName(doc.name, existingFiles),
                                "document_type": doc.type,
                                "size": doc.size,
                                "tags": doc.tags,
                                "full_identifier": documentId,
                                "chunker_type": chunkerType,
                                "embedding_model": embeddingModel,

                            }
                        });
                        await postDocumentMetadata(
                            payload,
                            tokenCxt.token,
                            tokenCxt.userName,
                            handleOnUploadProgress
                        );
                    } else {
                        const payload = uploadFiles.map((doc) => {
                            const documentId = documentIds.find(id => id.includes(doc.name));
                            return {
                                "document_name": generateUniqueName(doc.name, existingFiles),
                                "document_type": doc.type,
                                "size": doc.size,
                                "tags": doc.tags,
                                "full_identifier": documentId,
                                "chunker_type": chunkerType,
                                "embedding_model": embeddingModel,
                            }
                        });
                        await postDocumentMetadata(
                            payload,
                            tokenCxt.token,
                            tokenCxt.userName,
                            handleOnUploadProgress
                        );
                    }

                } catch (error) {
                    console.error("Error occurred when posting document metadata:", error);
                    showAlert("Error occurred when posting document metadata. Please try again.", "error");
                }
            } else {
                showAlert(
                    response.data['error'] || "Error during the document submission. Please try again.",
                    "error", 15000
                );
            }
        } catch (error) {
            showAlert(
                "Error during the document submission. Please try again.",
                "error"
            );
        }

        setDocumentInfo(initialDocumentState);
        setUploadFiles([]);
        setIsLoading(false);
    };

    return (
        <Box p={4} mt={0} flexGrow={1}>
            <BasePaper customStyles={TabPaperStyle} elevation={0}>
                <Typography variant="body1" mt={1}>
                    Upload a file to enable querying and retrieving information from the document.
                </Typography>
                <Typography variant="body1" mt={2}>
                    Please be aware that currently only the 'Private' mode is available. Your uploaded data is not used to generate answers for any other users.
                </Typography>
                <Typography variant="body1" mt={2}>
                    We accept the following file formats: {acceptedFiles.join(", ")}, with a maximum file size of 10 MB per file.
                </Typography>
                <Typography variant="body1" mt={2}>
                    <b>Chunker selection</b>
                </Typography>
                <Typography variant="body1" mt={2}>
                    RiskGPT offers different chunking methods to process your files effectively:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1">
                            <b>Text Chunker:</b> Use this for basic splitting of any supported file type based on delimiters like new lines or paragraph breaks. Ideal for simple segmentation without semantic analysis.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <b>Semantic Chunker:</b> This option analyzes the meaning and structure of text, dividing it into logical units like sentences or topics. Best for unstructured data such as plain text, emails, or articles.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <b>JSON Chunker:</b> Specifically designed for JSON files, preserving the structure and relationships within the data. Choose this for optimal handling of JSON data.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <b>Code Chunker:</b> If you're working with code files, this option segments the code based on its syntactic structure (e.g., functions, classes).
                        </Typography>
                    </li>
                </ul>
                <Typography variant="body1" mt={2}>
                    <b>Note:</b> For structured data formats like CSV and JSON, we recommend using the Text Chunker or the dedicated JSON Chunker for optimal results. You can configure the desired chunker type in the Settings based on your file format and analysis goals.
                </Typography>
                <Box display="flex" alignItems="center" sx={{ border: 1, borderColor: 'grey.500', p: 1, borderRadius: 1 }}>
                    <Typography variant="body1" sx={{ mr: 1 }}>
                        Selected Chunker: <strong>{chunkerType}</strong>
                    </Typography>
                    <WarningIcon style={{ color: theme.palette.warning.main }} />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                        Please verify that your selected chunker is appropriate for your file types.
                    </Typography>
                </Box>
                <Typography variant="body1" mt={4}>
                    <strong>Embedding Model Selection</strong>
                </Typography>
                <Typography variant="body1" mt={2}>
                    You can choose from four embedding models to process your text, each with increasing capabilities. It is crucial to use the same model for embedding and querying to ensure consistency:
                </Typography>
                <ul>
                <li><Typography variant="body1">Text Embedding ADA v2</Typography></li>
                    <li><Typography variant="body1">Text Embedding v3 Small</Typography></li>
                    <li><Typography variant="body1">Text Embedding v3 Medium</Typography></li>
                    <li><Typography variant="body1">Text Embedding v3 Large</Typography></li>
                </ul>
                <Box mt={2} display="flex" alignItems="center" sx={{ border: 1, borderColor: 'grey.500', p: 1, borderRadius: 1 }}>
                    <Typography variant="body1" sx={{ mr: 1 }}>
                        Current Embedding Model: <strong>{embeddingModel}</strong>
                    </Typography>
                    <CheckCircleIcon style={{ color: theme.palette.success.main }} />
                </Box>
            </BasePaper>
            <Dialog
                open={openCustomDocDialog}
                onClose={handleCloseCustomDocDialog}
                aria-labelledby="dialog-custom-doc-title"
                aria-describedby="dialog-custom-doc-description"
                sx={{ zIndex: 1302 }}
            >
                <DialogTitle>
                    <Typography variant="h5" fontWeight="bold" sx={{ textAlign: "center" }} id="dialog-custom-doc-title">
                        {!!docPreview ? `Doc Preview` : `Add Custom Document`}
                    </Typography>
                </DialogTitle>
                <DialogContent
                    sx={{
                        "& .Mui-disabled": {
                            backgroundColor: theme.palette.background.paper,
                            WebkitTextFillColor: theme.palette.secondary.main,
                            color: theme.palette.secondary.main
                        }
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <BaseTextField
                                isFullWidth={true}
                                variant="outlined"
                                size="medium"
                                isRequired={true}
                                placeholder="Document Title"
                                value={documentInfo.title}
                                isDisabled={!!docPreview}
                                handleOnChange={(e) =>
                                    setDocumentInfo({ ...documentInfo, title: e.target.value })
                                }
                            />
                        </Grid>
                        <Grid item md={8}>
                            <BaseTextField
                                isFullWidth={true}
                                variant="outlined"
                                size="medium"
                                isRequired={true}
                                placeholder="Document Tags (comma-separated)"
                                value={documentInfo.tags}
                                isDisabled={!!docPreview}
                                handleOnChange={(e) =>
                                    setDocumentInfo({ ...documentInfo, tags: e.target.value })
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <BaseTextField
                                isFullWidth={true}
                                variant="outlined"
                                size="medium"
                                isMultiline={true}
                                rows={15}
                                isRequired={true}
                                isDisabled={!!docPreview}
                                placeholder="Document Body&#13;Type or paste your document's content here."
                                value={documentInfo.rawText}
                                handleOnChange={(e) =>
                                    setDocumentInfo({ ...documentInfo, rawText: e.target.value })
                                }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button sx={popUpButtonStyle} onClick={handleCloseCustomDocDialog}>Close</Button>
                    {!docPreview && <Button sx={popUpButtonStyle} onClick={handleAddCustomDoc}>Add</Button>}
                </DialogActions>
            </Dialog>

            <DropZone
                handleSubmit={handleSubmit}
                initialDocumentState={initialDocumentState}
                uploadFiles={uploadFiles}
                setUploadFiles={setUploadFiles}
                removeAllUploadFiles={removeAllUploadFiles}
                removeUploadFile={removeUploadFile}
                handleOpenPreview={handleOpenPreview}
                onFile={handleFile}
            />
            <Box mt='5vh' display="flex" justifyContent="center">
                {/* <BaseButton
                    variant="contained"
                    color="primary"
                    startIcon={<UploadIcon />}
                    handleOnClick={handleSubmit}
                    sx={{
                        transition: "all 0.5s ease",
                        "&.Mui-disabled": {
                            background: "",
                            textShadow: "none",
                            boxShadow: "none",
                        },
                    }}
                    disabled={!uploadFiles || uploadFiles.length === 0 || isLoading}
                >
                    {isLoading ? <CircularProgress size={24} /> : "Upload"}
                </BaseButton> */}
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<UploadIcon />}
                    onClick={handleSubmit}
                    sx={{
                        transition: "all 0.5s ease",
                        "&.Mui-disabled": {
                            background: "",
                            textShadow: "none",
                            boxShadow: "none",
                        },
                    }}
                    disabled={!uploadFiles || uploadFiles.length === 0 || isLoading}
                >
                    {isLoading ? <CircularProgress size={24} /> : "Upload"}
                </Button>
            </Box>


        </Box>
    );
};

export default UploadFile;
