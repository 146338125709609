import React, {useCallback, useMemo, useContext} from "react";
import { useDropzone } from "react-dropzone";
import { Grid, Typography, List, ListItemText, ListItem, IconButton, ListItemIcon, Button, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ArticleIcon from "@mui/icons-material/Article";
import PreviewIcon from "@mui/icons-material/Preview";
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

import { AlertContext } from "../../context/AlertContext";
import BaseTextField from "../../base_components/BaseTextField";
import BaseTooltip from "../../base_components/BaseTooltip";

// PDF Parsing
import { pdfjs } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const DropZone = ({ uploadFiles, setUploadFiles, removeAllUploadFiles, initialDocumentState, removeUploadFile, handleOpenPreview, onFile }) => {
    const theme = useTheme();

    const {showAlert} = useContext(AlertContext);

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 1,
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.primary.main,
        borderStyle: 'dashed',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        outline: 'none',
        transition: `border .24s ${theme.transitions.easing.easeInOut}`,
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily,
        cursor: 'pointer',
        marginTop: "20px"
    };

    const focusedStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };
    
    const popUpButtonStyle = {
        color: theme.palette.text.primary,
        background: theme.palette.background.default 
    }

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (acceptedFiles?.length) {
            setUploadFiles([]);
            onFile(acceptedFiles);
            acceptedFiles.forEach(file => {
                const reader = new FileReader();

                //Begin reading the file to check for UTF-8 encoding
                reader.readAsArrayBuffer(file);

                //Function to handle the file once it has been read
                reader.onload = function (event) {
                    try {
                        if (file.type !== "application/pdf") {
                            //Create a new TextDecoder object to decode the ArrayBuffer
                            const arrayBuffer = event.target.result;
                            const decoder = new TextDecoder('utf-8', { fatal: true });

                            // Attempt to decode the ArrayBuffer as UTF-8
                            decoder.decode(arrayBuffer);
                        }

                        //If successful, read the file as text
                        reader.onabort = () => showAlert("file reading was aborted", "error");
                        reader.onerror = () => showAlert("file reading has failed", "error");
                        reader.onload = (event) => {
                            const rawText = reader.result;
                            handleUploadFile(rawText, file, setUploadFiles); 
                        }
                        if (file.type === "application/pdf") {
                            reader.readAsArrayBuffer(file);
                        }else{
                            reader.readAsText(file);
                        }
                        
                        //If successful, show a success message about proper encoding. 
                        showAlert("All files are in UTF-8 format.", "success");

                    } catch {
                        //If the file is not in UTF-8 format, show an error message.
                        showAlert(`File ${file.name} is not in UTF-8 format. Please convert to UTF-8 and try again.`, "error");
                    } 
                };
                //If the file cannot be read, show an error message.
                reader.onerror = function () {
                    showAlert(`Failed to read file: ${file.name}`, "error");
                };
            })
        }
    }, []);

    const handleUploadFile = (fileReaderRawText, file, setUploadFiles) => {
        let resultText = fileReaderRawText;
        const fileType = file.type;
        const fileName = file.name;
        const fileTitle = fileName
        if (fileType === "application/xml") {
            try {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(fileReaderRawText, "text/xml");
                resultText = xmlDoc.documentElement.textContent; 
            } catch (error) {
                console.error("Error parsing XML file", error);
                return;
            }
            } else if (fileType === "application/pdf") {
                const pdfData = new Uint8Array(fileReaderRawText);

                new pdfjs.getDocument({ data: pdfData }).promise.then((pdf) => {
                    let allText = [];

                    const promises = [];
                    for (let i = 1; i <= pdf.numPages; i++) {
                        promises.push(
                        pdf.getPage(i).then((page) => {
                                return page.getTextContent().then((textContent) => {
                                    return textContent.items.map(item => item.str).join(' ');
                                });
                            })
                        );
                    }

                    Promise.all(promises).then((texts) => {
                        allText = texts.join('\n\n'); 
                        setUploadFiles(previousUploadFiles => [
                            ...previousUploadFiles,
                            Object.assign({...initialDocumentState}, {
                                title: fileTitle, 
                                rawText: allText, 
                                name: fileName, 
                                type: fileType, 
                                size: file.size,
                                file: file
                            })
                        ]) 
                    });


                }).catch((error) => {
                    console.log("Error parsing pdf file", error);
                });
                return;
            }

        setUploadFiles(previousUploadFiles => [
            ...previousUploadFiles,
            Object.assign({...initialDocumentState}, {
                title: fileTitle, 
                rawText: resultText,
                name: fileName,
                type: fileType,
                size: file.size,
                file: file
            })
        ])
    }
    

    const {
        acceptedFiles, 
        getRootProps, 
        getInputProps, 
        isDragActive, 
        isDragAccept, 
        isDragReject, 
        isFocused
    } = useDropzone({ 
        onDrop,
        accept: {
            "application/json": [".json"],
            "application/pdf": [".pdf"],
            "text/plain": [".txt"],
            "text/html": [".html", ".htm"],
            "text/xml": [".xml"],
            "text/csv": [".csv"],
            "text/javascript": [".js", ".jsx"],
            "application/octet-stream": [".py", ".js", ".jsx", ".java", ".cpp", ".cs", ".go", ".rs", ".scala", ".rb", ".tex", ".md"],
        },
        maxSize: 1024 * 10000 // 10 MB
    });
    

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const handleTagChange = useCallback((fileName, newTags) => {
        setUploadFiles(prevUploadFiles => (
            prevUploadFiles.map(file =>
                file.name === fileName ? { ...file, tags: newTags } : file)
        ));
    }, [setUploadFiles]);
    
    const files = uploadFiles.map((file, index) => (
        <ListItem 
            key={`${file.name}_${index}`}
            secondaryAction={
                [
                <BaseTooltip isArrow title="Tags are an optional way to add custom, comma-separated labels to your documents">
                    <HelpOutlineRoundedIcon sx={{ fontSize: 22, cursor: "pointer", marginTop: "4px", marginRight: "5px" }} />
                </BaseTooltip>,
                <BaseTextField
                variant="outlined"
                size="small"
                placeholder="Tags"
                value={file.tags}
                handleOnChange={(e) =>
                    handleTagChange(file.name, e.target.value)
                }
                />,
                <IconButton edge="end" aria-label="preview" onClick={()=>handleOpenPreview(file.name)}>
                    <PreviewIcon variant="small" />
                </IconButton>,
                <IconButton edge="end" aria-label="delete" onClick={()=>removeUploadFile(file.name)} sx={{ml:1}}>
                    <CloseIcon variant="small" />
                </IconButton>
                ]
            }
            sx={{mt: 1}}
        >
            <ListItemIcon>
                {file.type === "application/pdf" ? 
                    <PictureAsPdfIcon variant="medium" fontSize="large"/> :
                    file.type === "application/text" ?
                    <TextSnippetIcon variant="medium" fontSize="large"/> :
                    <ArticleIcon variant="medium" fontSize="large"/>
                }
            </ListItemIcon>
            <ListItemText primary={file.name} secondary={`${file.size/1000} KBs`} sx={{ overflow: "hidden", maxWidth: 200 }}/>
            
        </ListItem>

    ));

    return (
        <Grid container sx={{display: "flex", justifyContent: "space-around"}}>
            <Grid item xs={12} {...getRootProps({style})}>
                <input {...getInputProps()} />
                {isDragActive? (
                    <p>Drop the files here ...</p>
                ) : (
                    <p>Drag and drop some files here, or click to select files</p>
                )}
            </Grid>
            {uploadFiles.length > 0 && (<Grid item xs={12} sx={{mt: 2, maxHeight: 250, padding: 1, overflow: "auto"}}>
                <Typography variant="h6" sx={{width: "100%", maxWidth: 525, display: "flex", justifyContent: "space-between"}}>
                    Uploaded Docs
                    <Button onClick={removeAllUploadFiles} sx={popUpButtonStyle}>Remove All</Button>
                </Typography>
                <List sx={{width: "100%", maxWidth: 525}}>
                    {files}
                </List>
            </Grid>)}
        </Grid>
    )
}

export default DropZone;