import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";

// base components
import BaseAgGridTable from "../../base_components/BaseAgGridTable";
import BaseTooltip from "../../base_components/BaseTooltip";
import BaseButton from "../../base_components/BaseButton";
import UploadResources from "../UploadResources/UploadResources";

// MUI components
import IconButton from "@mui/material/IconButton";
import { Box, Typography } from "@mui/material";

// Mui Icons
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import SelectAllRoundedIcon from '@mui/icons-material/SelectAllRounded';
import DeselectRoundedIcon from '@mui/icons-material/DeselectRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import {CircularProgress} from "@mui/material";

// Context
import { AlertContext } from "../../context/AlertContext";
import TokenContext from "../../context/TokenContext";

// Utility functions
import putDocument from "../../api/putDocument";

import { DOCUMENT_TABLE_TOOLTIP } from '../../config/constants';

const CustomIconStyle = { fontSize: 23, cursor: "pointer" };

const DocumentTable = ({
  data,
  handleInfoClick,
  onUploadClick,
  onDeleteClick,
  handleActivateClick,
  selectedUserDocuments,
  onSelectAllClick,
  onDeselectAllClick,
  stagingThreadPreferences,
}) => {
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const tokenCxt = useContext(TokenContext);
  const { showAlert } = useContext(AlertContext);

  const columns = useMemo(() => [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      lockPosition: true,
    },
    { headerName: 'Document Name', field: 'docName', flex: 6 },
    { headerName: 'Document Type', field: 'docType', flex: 3 },
    { headerName: 'Tags', field: 'tags', flex: 3, editable: true },
    {
      headerName: 'Size',
      field: 'size',
      flex: 3,
      valueGetter: params => parseInt(params.data.size, 10),
      cellRenderer: params => bytesToSize(params.value)
    },
    { headerName: 'Chunker Type', field: 'chunkerType', flex: 3 },
    { headerName: 'Embedding Model', field: 'embeddingModel', flex: 3 },
    { headerName: 'Created At', field: 'createdAt', flex: 3 },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 2,
      cellRenderer: (params) => (
        <div>
          <IconButton onClick={() => handleRowClick(params.data.id)}>
            <BaseTooltip isArrow title="View details">
              <InfoRoundedIcon />
            </BaseTooltip>
          </IconButton>
          <IconButton onClick={() => handleSingleDeleteClick(params.data.id)}>
            <BaseTooltip isArrow title="Delete Document">
              <DeleteRoundedIcon />
            </BaseTooltip>
          </IconButton>
        </div>
      ),
    }
  ], []);

  const bytesToSize = useCallback((bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  }, []);

  useEffect(() => {
    setRows(data);
  }, [data]);

  const getRowStyle = useCallback((params) => {
    const selectedIds = selectedUserDocuments.map(row => row.id);
    return selectedIds.includes(params.data.id) ? { background: '#3CE2B3', color: 'black' } : {};
  }, [selectedUserDocuments]);

  const handleCellEditingStopped = useCallback((event) => {
    if (event.column.colId === 'tags') {
      putDocument(tokenCxt.token, event.data.document_id, null, event.value);
    }
    if (event.column.colId === 'docName') {
      putDocument(tokenCxt.token, event.data.document_id, event.value, null);
    }
  }, [tokenCxt.token]);

  const handleRowClick = useCallback((docId) => {
    handleInfoClick(docId);
  }, [handleInfoClick]);

  const handleSingleDeleteClick = useCallback((id) => {
    onDeleteClick([id]);
  }, [onDeleteClick]);

  const handleSelectionUpdate = useCallback((checkedRows) => {
    setSelectedRows(checkedRows);
  }, []);

  const onActivateClick = useCallback(() => {
    const ids = selectedRows.map(row => row.id);
    handleActivateClick(ids);
  }, [selectedRows, handleActivateClick]);

  const handleDeselectAllClick = async () => {
    await onDeselectAllClick();
  }

  const handleSelectAllClick = async () => {
    await onSelectAllClick();
  }

  const handleUploadClick = () => {
    setDrawerOpen(true);
  };

  return (
    <>
    { drawerOpen && <UploadResources open={() => setDrawerOpen(true)} close={() => setDrawerOpen(false) } stagingThreadPreferences={stagingThreadPreferences} />}
    <Box style={{ overflowX: "auto", width: "100%" }}>
      <Typography variant="body1" mt={1} sx={{ paddingBottom: "5px", paddingLeft: "9px", fontWeight: "bold" }}>
        Please click the 'Toggle Active Status' button after selecting any checkboxes to activate or deactivate your documents
      </Typography>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <IconButton onClick={handleSelectAllClick}>
            <BaseTooltip isArrow title="Activate ALL Documents">
              <SelectAllRoundedIcon />
            </BaseTooltip>
          </IconButton>

          <IconButton onClick={handleDeselectAllClick}>
            <BaseTooltip isArrow title="Deactivate ALL Documents">
              <DeselectRoundedIcon />
            </BaseTooltip>
          </IconButton>

          <IconButton onClick={handleUploadClick}>
            <BaseTooltip isArrow title="Upload Resource">
              <CloudUploadRoundedIcon />
            </BaseTooltip>
          </IconButton>

          <IconButton onClick={() => onDeleteClick(selectedRows.map(row => row.id))}>
            <BaseTooltip isArrow title="Delete Selected Document(s)">
              <DeleteRoundedIcon />
            </BaseTooltip>
          </IconButton>
        </div>
        <Box display="flex" justifyContent="flex-end">
          <BaseTooltip title={DOCUMENT_TABLE_TOOLTIP} isArrow>
            <HelpOutlineRoundedIcon sx={{ fontSize: 23, cursor: "pointer" }} />
          </BaseTooltip>
        </Box>
      </div>

      <div>
        <BaseAgGridTable
          rows={rows}
          columns={columns}
          updateCurrentSelection={handleSelectionUpdate}
          getRowStyle={getRowStyle}
          onCellEditingStopped={handleCellEditingStopped}
        />
      </div>

      <Box mt={5} display="flex" justifyContent="center">
        <BaseButton
          variant="contained"
          color="primary"
          handleOnClick={onActivateClick}
          startIcon={<TaskRoundedIcon sx={{ color: "white" }} />}
          sx={{
            transition: "all 0.5s ease",
            "&.Mui-disabled": {
              background: "",
              textShadow: "none",
              boxShadow: "none",
            },
          }}
          disabled={selectedRows.length === 0 }
        >
           Toggle Active Status
        </BaseButton>
      </Box>
    </Box>
    </>
  );
};

export default DocumentTable;
